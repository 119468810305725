import { App, InjectionKey } from 'vue'
import Rollbar, { LogArgument } from 'rollbar'

// Docs: https://docs.rollbar.com/docs/javascript

const ROLLBAR_CLIENT_TOKEN = '317bd119df80476c828dce14abdf9218'
const ENV = import.meta.env.VITE_APP_ENV as string

// include and initialize the rollbar library with your access token
const rollbar = new Rollbar({
  accessToken: ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: ENV,
})

export const RollbarKey: InjectionKey<Rollbar> = Symbol('Rollbar')

export default {
  install(app: App) {
    // this.$router in Options API
    app.config.globalProperties.$rollbar = rollbar

    // useRollbar() in Composition API
    app.provide(RollbarKey, rollbar)

    app.config.errorHandler = (err, vm, info) => {
      // handle error
      // `info` is a Vue-specific error info, e.g. which lifecycle hook
      // the error was found in
      vm.$rollbar.error(err as unknown as LogArgument)

      throw err // rethrow
    }
  },
}
