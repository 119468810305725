import { request } from '@/services/api'

import { ActionInterface } from '@/store'

export type Post = {
  attempted_deletion_count?: number
  attempted_submissions_count?: number
  content: string
  created_at?: string
  created_by?: string
  delete_at?: string | null
  deleted_at?: string | null
  deletion_upvote_threshold?: number | null
  id?: string
  image_url: string
  lastmodified_at?: string
  lastmodified_by?: string
  nsfw: boolean
  permalink?: string
  post_at: string
  posted_at?: string | null
  reddit_account?: string
  reddit_submission_id?: string
  resubmit: boolean
  schedule_id?: string | null
  send_replies?: boolean
  subreddit: string
  title: string
}

export type PostsState = {
  posts: Array<Post>
}

const state = (): PostsState => ({
  posts: [],
})

const getters = {
  withoutSchedule: (state: PostsState) => {
    return state.posts.filter((post) => !post.schedule_id)
  },
  bySchedule: (state: PostsState) => (scheduleId: string) => {
    return state.posts.filter((post) => post.schedule_id === scheduleId)
  },
  byId: (state: PostsState) => (postId: string) => {
    return state.posts.find((post) => post.id === postId)
  },
}

const mutations = {
  UPDATE_ALL (state: PostsState, payload: Array<Post>) {
    state.posts = payload
  },
  ADD_OR_UPDATE (state: PostsState, payload: Post) {
    const index = state.posts.findIndex((post) => post.id === payload.id)

    index >= 0
      ? state.posts.splice(index, 1, payload)
      : state.posts.push(payload)
  },
  DELETE (state: PostsState, id: string) {
    const index = state.posts.findIndex((post) => post.id === id)
    state.posts.splice(index, 1)
  },
}

const actions = {
  async getAll ({ commit }: ActionInterface<PostsState>): Promise<void> {
    const { data } = await request('/post/', { method: 'GET' })

    commit('UPDATE_ALL', data.results)
  },
  async getAllByScheduleId ({ commit }: ActionInterface<PostsState>, { scheduleId }: { scheduleId: string }): Promise<Post[]> {
    const { data } = await request(`/schedule/${scheduleId}/history`, { method: 'GET' })

    for (const post of data.results) {
      commit('ADD_OR_UPDATE', post)
    }
  },
  async getById ({ commit, state }: ActionInterface<PostsState>, { id }: { id: string }): Promise<Post> {
    const existing = state.posts.find((post) => post.id === id)
    if (existing) return existing

    const { data } = await request(`/post/${id}/`, { method: 'GET' })

    commit('ADD_OR_UPDATE', data)

    return data as Post
  },
  async create ({ commit }: ActionInterface<PostsState>, payload: Post): Promise<Post> {
    const { data } = await request('/post/', {
      method: 'POST',
      body: JSON.stringify({ ...payload }),
    })

    commit('ADD_OR_UPDATE', data)

    return data as Post
  },
  async update ({ commit }: ActionInterface<PostsState>, payload: Post): Promise<Post> {
    const { data } = await request(`/post/${payload.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({ ...payload }),
    })

    commit('ADD_OR_UPDATE', data)

    return data as Post
  },
  async delete ({ commit }: ActionInterface<PostsState>, { id }: { id: string }): Promise<void> {
    await request(`/post/${id}/`, { method: 'DELETE' })

    commit('DELETE', id)
  },
  async deleteOnReddit (context: ActionInterface<PostsState>, { id }: { id: string }): Promise<boolean> {
    const { ok } = await request(`/post/${id}/reddit_delete/`, { method: 'DELETE' })

    return ok
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
