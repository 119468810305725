import { request, RequestResult } from '@/services/api'
import { storeTokens, getTokens, clearTokens } from '@/services/auth'
import { ActionInterface } from '@/store'

type LoginResponse = {
  refresh: string
  access: string
}

type Tokens = {
  access: string
  refresh: string
  lastRefreshed: string
}

type LoginPayload = {
  username: string
  password: string
}

export type AuthState = {
  accessToken: string
  refreshToken: string
  lastRefreshed: string // Date as string
}

/**
 * Time in seconds until the access token needs to be refreshed
 */
const REFRESH_INTERVAL = 300

/**
* Time in seconds until a full login is required
*/
const LOGIN_INTERVAL = 3600

/**
 * Get the time since the refresh token was last refreshed
 *
 * @param lastRefreshed stored time since the auth token was last refreshed
 * @returns time since last refresh in seconds
 */
const getTimeSinceRefresh = (lastRefreshed: string): number => (Date.now().valueOf() - new Date(lastRefreshed).valueOf()) / 1000

const state = (): AuthState => ({
  accessToken: '',
  refreshToken: '',
  lastRefreshed: '',
})

const mutations = {
  UPDATE (state: AuthState, payload: LoginResponse) {
    state.accessToken = payload?.access || ''
    state.refreshToken = payload?.refresh || ''

    const now = new Date().toString()

    if (payload?.refresh) {
      state.lastRefreshed = now
    }

    storeTokens({
      access: payload?.access,
      refresh: payload?.refresh,
      lastRefreshed: payload?.refresh ? now : undefined,
    })
  },
  UPDATE_FROM_TOKENS (state: AuthState, payload: Tokens) {
    state.accessToken = payload.access || ''
    state.refreshToken = payload.refresh || ''
    state.lastRefreshed = payload.lastRefreshed || ''
  },
  CLEAR (state: AuthState) {
    state.accessToken = ''
    state.refreshToken = ''
    state.lastRefreshed = ''

    clearTokens()
  },
}

const getters = {
  timeSinceRefresh: (state: AuthState): number => getTimeSinceRefresh(state.lastRefreshed),
}

const actions = {
  /**
   * Check if login is necessary, refreshing the token if able. Returns true if login is unecessary.
   */
  async checkAuth ({ state, commit, dispatch, getters }: ActionInterface<AuthState>): Promise<boolean> {
    // TODO: need to handle refresh on load to also force a login if expired
    // TODO: Might be served with better logic here tbh
    // TODO: should probably try/catch this better to indicate what the error is when it happens

    // Attempt to load from localstorage when state is empty
    if (!state.refreshToken) {
      commit('UPDATE_FROM_TOKENS', getTokens())
    }

    if (!getters.timeSinceRefresh) {
      return false
    }

    if (getters.timeSinceRefresh > LOGIN_INTERVAL) {
      // force a login
      return false
    } else if (getters.timeSinceRefresh > REFRESH_INTERVAL) {
      console.log("REFRESHING", getters.timeSinceRefresh, REFRESH_INTERVAL)
      const result = await dispatch('refresh')

      if (result.ok) {
        return true
      }

      return false
    } else {
      // Auth token is up to date
      return true
    }
  },

  /**
   * Attempt to login, returning true if successful.
   */
  async login ({ commit }: ActionInterface<AuthState>, { username, password }: LoginPayload): Promise<RequestResult> {
    const { ok, status, data } = await request('/auth/token', {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
      credentials: 'omit',
    })

    commit('UPDATE', data)

    return { ok, status }
  },

  /**
   * Logout, clear the tokens
   */
  async logout({ commit }: ActionInterface<AuthState>): Promise<void> {
    await request('/auth/logout_all', {
      method: 'POST',
    })
    // clear the tokens locally anyway even if the api fails to clear them
    commit('CLEAR')
  },

  /**
   * Attempt to refresh the access token, returning true if successful
   */
  async refresh ({ commit, state }: ActionInterface<AuthState>): Promise<RequestResult> {
    const { ok, status, data } = await request('/auth/token/refresh', {
      method: 'POST',
      body: JSON.stringify({
        refresh: state.refreshToken,
      }),
      credentials: 'omit',
    })

    commit('UPDATE', data)

    return { ok, status }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
