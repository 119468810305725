import { request, RequestResult } from '@/services/api'
import { ActionInterface } from '@/store'
import { Dict } from '@/types'

type AccountAccount = {
  id: string
  tier: string
  user: string
  email_verified: boolean
}

type AccountSecurityQuestions = {
  answered: string[]
  possible: string[]
}

export type AccountState = {
  email: string
  username: string
  account: AccountAccount,
  // A listing of security questions that have been answered.
  // TODO: is there a security reason not to list these here and to keep them more
  // ephemeral? We could store them in session storage and have a get function outside
  // of vuex if so.
  securityQuestions: AccountSecurityQuestions,
}

const state = (): AccountState => ({
  email: '',
  username: '',
  account: {
    id: '',
    tier: '',
    email_verified: false,
    user: '',
  },
  securityQuestions: {
    answered: [],
    possible: [
      'street_security_answer',
      'pet_security_answer',
      'city_security_answer',
      'teacher_security_answer',
    ],
  },
})

const getters = {
  email: (state: AccountState): string => state.email,
  username: (state: AccountState): string => state.username,
  userId: (state: AccountState): string => state.account.user,
  isVerified: (state: AccountState): boolean => state.account.email_verified,
  answeredSecurityQuestions: (state: AccountState): string[] => state.securityQuestions.answered,
  possibleSecurityQuestions: (state: AccountState): string[] => state.securityQuestions.possible,
}

const mutations = {
  UPDATE (state: AccountState, payload: AccountState) {
    state.email = payload.email
    state.username = payload.username
    state.account = payload.account
  },
  CLEAR (state: AccountState) {
    state.email = ''
    state.username = ''
    state.account = {
      id: '',
      tier: '',
      email_verified: false,
      user: '',
    }
    state.securityQuestions.answered = []
  },
  UPDATE_SECURITY_QUESTIONS(state: AccountState, payload: string[]) {
    state.securityQuestions.answered = payload
  },
}

const actions = {
  async getAccount ({ commit }: ActionInterface<AccountState>): Promise<RequestResult> {
    const result = await request('/account/', { method: 'GET' })
    commit('UPDATE', result.data.results[0])
    return result
  },
  async editProfile (
    { commit, getters, dispatch }: ActionInterface<AccountState>,
    payload: { email?: string, username?: string }
  ): Promise<RequestResult> {
    const result = await request(`/account/${getters.userId}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        email: payload.email ?? undefined,
        username: payload.username ?? undefined,
      }),
    })

    if (payload.email !== getters.email) {
      await dispatch('sendVerificationEmail', {
        email: payload.email,
      })
    }

    commit('UPDATE', result.data)

    return result
  },
  async changePassword (
    { getters }: ActionInterface<AccountState>,
    { old_password, password, confirm_password }: { old_password: string, password: string, confirm_password: string }
  ): Promise<RequestResult> {
    const result = await request(`/account/change_password/${getters.userId}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        old_password,
        password,
        confirm_password,
      }),
    })

    return result
  },
  async sendVerificationEmail (context: ActionInterface<AccountState>, { email }: { email: string }): Promise<RequestResult> {
    const result = await request('/account/send_verification_email', {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    })

    return result
  },
  async verifyEmail (context: ActionInterface<AccountState>, { token }: { token: string }): Promise<RequestResult> {
    const result = await request('/account/verify_email', {
      method: 'PATCH',
      body: JSON.stringify({
        email_verification_token: token,
      }),
    })

    return result
  },
  async sendUsernameReminder (context: ActionInterface<AccountState>, { email }: { email: string }): Promise<RequestResult> {
    const result = await request('/account/send_username_reminder', {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      credentials: 'omit',
    })

    return result
  },
  async sendPasswordResetToken (context: ActionInterface<AccountState>, { email }: { email: string }): Promise<RequestResult> {
    const result = await request('/account/send_reset_token', {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      credentials: 'omit',
    })

    return result
  },
  async sendPasswordResetVerify (context: ActionInterface<AccountState>, { password_reset_token, user_id }: { password_reset_token: string, user_id: string }): Promise<RequestResult> {
    const result = await request('/account/verify_reset_token', {
      method: 'PATCH',
      body: JSON.stringify({
        password_reset_token,
        user_id,
      }),
      credentials: 'omit',
    })

    return result
  },
  async resetPassword (
    { state }: ActionInterface<AccountState>,
    { password, confirm_password }: { password: string, confirm_password: string }
  ): Promise<RequestResult> {
    const result = await request(`/account/change_password_forgotten_pw/${state.account.user}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        password,
        confirm_password,
      }),
    })

    return result
  },

  async checkSecurityQuestions (
    context: ActionInterface<AccountState>,
    { questions, user_id }: { questions: Dict<string>, user_id: string }
  ): Promise<RequestResult> {
    const result = await request(`/account/security-question/${user_id}/check_answers/`, {
      method: 'POST',
      body: JSON.stringify({
        ...questions,
      }),
      credentials: 'omit',
    })

    return result
  },
  async getSecurityQuestions ({ commit }: ActionInterface<AccountState>): Promise<RequestResult> {
    const result = await request('/account/security-question/', { method: 'GET' })
    commit('UPDATE_SECURITY_QUESTIONS', result.data.reset_questions)
    return result
  },
  async updateSecurityQuestions ({ commit, state }: ActionInterface<AccountState>, payload: Dict<string>): Promise<RequestResult> {
    const result = await request(`/account/security-question/${state.account.user}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        ...payload,
      }),
    })
    commit('UPDATE_SECURITY_QUESTIONS', result.data.reset_questions)
    return result
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
