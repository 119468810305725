import { request } from '@/services/api'

import { ActionInterface } from '@/store'

export type Schedule = {
  id?: string
  name?: string
  subreddit: string
  reddit_account: string
  comment_text?: string
  active?: boolean
  archived?: boolean
  nsfw?: boolean
  start_at: string
  expiration_date?: string
  delete_after?: string
  deletion_upvote_threshold?: number
  interval?: string
  images_queue?: string[]
  randomize_images_queue?: boolean
  repeat_images_queue?: boolean
  titles_queue?: string[]
  randomize_titles_queue?: boolean
  repeat_titles_queue?: boolean
  queue_position?: number
  created_at?: string
  created_by?: string
  lastmodified_at?: string
  lastmodified_by?: string
}

export type SchedulesState = {
  schedules: Schedule[]
}

const state = (): SchedulesState => ({
  schedules: [],
})

const getters = {}

const mutations = {
  UPDATE_ALL (state: SchedulesState, payload: Schedule[]) {
    state.schedules = payload
  },
  ADD (state: SchedulesState, payload: Schedule) {
    state.schedules.push(payload)
  },
  UPDATE (state: SchedulesState, payload: Schedule) {
    const index = state.schedules.findIndex((schedule) => schedule.id === payload.id)
    state.schedules.splice(index, 1, payload)
  },
  DELETE (state: SchedulesState, id: string) {
    const index = state.schedules.findIndex((schedule) => schedule.id === id)
    state.schedules.splice(index, 1)
  },
}

const actions = {
  async getAll ({ commit }: ActionInterface<SchedulesState>): Promise<void> {
    const { data } = await request('/schedule/', { method: 'GET' })

    commit('UPDATE_ALL', data.results)
  },
  async getById ({ commit, state }: ActionInterface<SchedulesState>, { id }: { id: string }): Promise<Schedule> {
    const existing = state.schedules.find((schedule) => schedule.id === id)
    if (existing) return existing

    const { data } = await request(`/schedule/${id}/`, { method: 'GET' })

    commit('ADD', data)

    return data
  },
  async create ({ commit }: ActionInterface<SchedulesState>, payload: Schedule): Promise<Schedule> {
    const { data } = await request('/schedule/', {
      method: 'POST',
      body: JSON.stringify({ ...payload }),
    })

    commit('ADD', data)

    return data
  },
  async update ({ commit }: ActionInterface<SchedulesState>, payload: Schedule): Promise<Schedule> {
    const { data } = await request(`/schedule/${payload.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({ ...payload }),
    })

    commit('UPDATE', data)

    return data
  },
  async delete ({ commit }: ActionInterface<SchedulesState>, { id }: { id: string }): Promise<void> {
    await request(`/schedule/${id}/`, { method: 'DELETE' })

    commit('DELETE', id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
