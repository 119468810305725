import { Dict } from '@/types'

export const storeTokens = (tokens: Dict<string>) => {
  for (const [key, token] of Object.entries(tokens)) {
    localStorage.setItem(key, token)
  }
}

export const getTokens = () => {
  const access = localStorage.getItem('access') || ''
  const refresh = localStorage.getItem('refresh') || ''
  const lastRefreshed = localStorage.getItem('lastRefreshed') || ''

  return { access, refresh, lastRefreshed }
}

export const clearTokens = () => {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
  localStorage.removeItem('lastRefreshed')
}
