import { request, Pagination, PaginationError } from '@/services/api'

import { ActionInterface } from '@/store'

export enum IndeterminateBoolean {
  TRUE = "TRUE",
  FALSE = "FALSE",
  UNKNOWN = "UNKNOWN",
}

export const PAGE_SIZE = 50

export type SubredditTag = {
  id: number
  tag_name: string
}

export type Subreddit = {
  id: string
  created_at: string
  lastmodified_at: string
  subreddit_id?: string
  subreddit_name: string
  known_good: boolean
  known_bad: boolean
  NSFW: boolean
  SFW: boolean
  daily_post_limit?: number
  verification_required: IndeterminateBoolean,
  watermarks_allowed: IndeterminateBoolean,
  video_allowed: IndeterminateBoolean,
  alternative_friendly: IndeterminateBoolean,
  description?: string
  public_description?: string
  submit_text?: string
  exists: boolean
  visible: string
  tags: SubredditTag[]
}

export type SubredditFilters = {
  tags?: string[]
  SFW?: boolean
  NSFW?: boolean
  known_good?: boolean
  known_bad?: boolean
  verification_required?: IndeterminateBoolean
  watermarks_allowed?: IndeterminateBoolean
  video_allowed?: IndeterminateBoolean
  alternative_friendly?: IndeterminateBoolean
}

type SubredditsPaginatedResponse = {
  results: Subreddit[]
  count: number,
  next: string | null
  previous: string | null,
}

export type SubredditsGetParams = {
  pagination?: {
    direction?: string // 'next' or 'previous'
    limit?: number
    offset?: number
  }
  sorting?: string
  filters?: SubredditFilters
  search?: string
}

export type SubredditsState = {
  subreddits: Subreddit[],
  pagination: Pagination,
}

/**
 * Temporary workaround for returned pagination url's
 */
const stripUrl = (url: string): string => url.replace('http://localhost:8000', '')

const getOffset = (url: string): number => Number(url.match(/(?<=&offset=)(\d+)/g)[0]) || null

export const getQueryParamsByPage = (page: number): { limit: number, offset: number } => {
  // TODO: Might want a better way to handle this so that we aren't +/-'ing this all over
  // account for 0 indexing
  const index = page - 1
  const offset = index * PAGE_SIZE || undefined

  return {
    limit: PAGE_SIZE,
    offset,
  }
}

const state = (): SubredditsState => ({
  subreddits: [],
  pagination: {
    next: null,
    previous: null,
    count: null,
    offset: null,
  },
})

const getters = {
  /**
   * Get a subreddit by name
   *
   * @param {string} name a subreddit name
   * @returns {Subreddit} a subreddit
   */
  details: (state: SubredditsState) => (name: string) => {
    try {
      return state.subreddits.find((subreddit) => subreddit.subreddit_name === name)
    } catch {
      console.error(`subreddit r/${name} does not exist in SubArrow yet!`)
    }
  },
  /**
   * Calculate total pages and round up
   */
  totalPages: (state: SubredditsState): number => Math.ceil(state.pagination.count / PAGE_SIZE),
  /**
   * Determine the current page based on the next pages start number
   */
  currentPage: (state: SubredditsState, getters: { totalPages: number }): number => {
    // If no next, the current page is the last page available
    if (!state.pagination.next) return getters.totalPages

    return getOffset(state.pagination.next) / PAGE_SIZE
  },
}

const mutations = {
  PAGINATED_ADD (state: SubredditsState, payload: SubredditsPaginatedResponse) {
    state.subreddits = payload.results
    state.pagination.next = payload.next
    state.pagination.previous = payload.previous
    state.pagination.count = payload.count
    state.pagination.offset = payload.next ? getOffset(payload.next) : null
  },
  ADD (state: SubredditsState, payload: Subreddit) {
    state.subreddits.push(payload)
  },
  UPDATE (state: SubredditsState, payload: Subreddit) {
    const index = state.subreddits.findIndex((subreddit) => subreddit.id === payload.id)
    state.subreddits.splice(index, 1, payload)
  },
}

const actions = {
  async get ({ commit, state }: ActionInterface<SubredditsState>, params: SubredditsGetParams = {}): Promise<void> {
    let url = '/subreddit/'

    // TODO: this can probably be handled more gracefully in a standardized way for all endpoints
    // Also building this as a string piecemeal sucks, should generate an option and then print to string at the end
    // so we don't end up prefixing _everything_ with & or needing this first if check for ?

    // Add the query param signifier if necessary
    if (params?.pagination || params?.sorting || params?.filters) url += '?'

    // Pagination
    // TODO: this doesn't account for users modifying the PAGE_SIZE (i.e. limit)
    if (params?.pagination?.direction === 'next') {
      if (state.pagination.next) {
        url = stripUrl(state.pagination.next)
      } else {
        throw new PaginationError(state.pagination, 'next')
      }
    }

    if (params?.pagination?.direction === 'previous') {
      if (state.pagination.previous) {
        url = stripUrl(state.pagination.previous)
      } else {
        throw new PaginationError(state.pagination, 'previous')
      }
    }

    if (params?.pagination?.limit && params?.pagination?.offset) {
      url += `&limit=${params.pagination.limit}&offset=${params.pagination.offset}`
    }

    // Sorting
    if (params?.sorting) url = `${url}&ordering=${params.sorting}`

    // Filtering
    if (params?.filters) {
      let filters = ''

      for (const [key, value] of Object.entries(params.filters)) {
        filters += `&${key}=${value}`
      }

      url += filters
    }

    // Search (name)
    if (params?.search) url += `&search=${params.search}`

    if (url.includes('?')) {
      url = url.replace(/(?<=\?)(&)/g, '')
    }

    const { data } = await request(url, {
      method: 'GET',
    })

    commit('PAGINATED_ADD', data)
  },
  async getByName ({ commit, state }: ActionInterface<SubredditsState>, { name }: { name: string }): Promise<Subreddit> {
    const existing = state.subreddits.find((subreddit) => subreddit.subreddit_name === name)
    if (existing) return existing

    const { data } = await request(`/subreddit/?subreddit_name=${name}`, { method: 'GET' })

    commit('ADD', data.results[0])

    return data.results[0]
  },
  async create ({ commit }: ActionInterface<SubredditsState>, payload: Subreddit): Promise<Subreddit> {
    const { data } = await request('/subreddit/', {
      method: 'POST',
      body: JSON.stringify({ ...payload }),
    })

    commit('ADD', data)

    return data as Subreddit
  },
  async update ({ commit }: ActionInterface<SubredditsState>, payload: Subreddit): Promise<Subreddit> {
    const { data } = await request(`/subreddit/${payload.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({ ...payload }),
    })

    commit('UPDATE', data)

    return data as Subreddit
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
