import { request } from '@/services/api'

import { ActionInterface } from '@/store'

export type RedditAccount = {
  id?: string
  username?: string
  nickname?: string
  created_at?: string
  lastmodified_at?: string
  created_by?: string
  lastmodified_by?: string
}

type CreateRedditAccountPayload = {
  nickname: string
  refreshToken: string
}

type UpdateRedditAccountPayload = {
  id: string
  nickname: string
}

type DeleteRedditAccountPayload = {
  id: string
}

export type RedditAccountsState = {
  accounts: Array<RedditAccount>
}

const state = (): RedditAccountsState => ({
  accounts: [],
})

const getters = {
  getById: (state: RedditAccountsState) => (id: string) => {
    return state.accounts.find((account) => account.id === id)
  },
  hasAccounts: (state: RedditAccountsState) => state.accounts.length > 0,
}

const mutations = {
  UPDATE_ALL (state: RedditAccountsState, payload: Array<RedditAccount>) {
    state.accounts = payload
  },
  CLEAR (state: RedditAccountsState) {
    state.accounts = []
  },
  ADD (state: RedditAccountsState, payload: RedditAccount) {
    state.accounts.push(payload)
  },
  UPDATE (state: RedditAccountsState, payload: RedditAccount) {
    const index = state.accounts.findIndex((account) => account.id === payload.id)
    state.accounts.splice(index, 1, payload)
  },
  DELETE (state: RedditAccountsState, id: string) {
    const index = state.accounts.findIndex((account) => account.id === id)
    state.accounts.splice(index, 1)
  },
}

const actions = {
  async getAll ({ commit }: ActionInterface<RedditAccountsState>): Promise<void> {
    const { data } = await request('/reddit-account/', { method: 'GET' })

    commit('UPDATE_ALL', data.results)
  },
  async getById ({ commit, state }: ActionInterface<RedditAccountsState>, { id }: { id: string }): Promise<RedditAccount> {
    const existing = state.accounts.find((account) => account.id === id)
    if (existing) return existing

    const { data } = await request(`/reddit-account/${id}/`, { method: 'GET' })

    commit('ADD', data)

    return data
  },
  async create ({ state, commit }: ActionInterface<RedditAccountsState>, payload: CreateRedditAccountPayload): Promise<RedditAccount> {
    const { data } = await request('/reddit-account/', {
      method: 'POST',
      body: JSON.stringify({
        nickname: payload.nickname,
        refresh_token: payload.refreshToken,
      }),
    })
    // If the account already exists locally, we need to override it (why this would happen, idk but it could)
    if (state.accounts.some((account) => account.id === (data as RedditAccount).id)) {
      commit('UPDATE', data)
    } else {
      commit('ADD', data)
    }

    return data
  },
  async update ({ commit }: ActionInterface<RedditAccountsState>, payload: UpdateRedditAccountPayload): Promise<RedditAccount> {
    const { data } = await request(`/reddit-account/${payload.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        nickname: payload.nickname,
      }),
    })

    commit('UPDATE', data)

    return data
  },
  async delete ({ commit }: ActionInterface<RedditAccountsState>, payload: DeleteRedditAccountPayload): Promise<void> {
    await request(`/reddit-account/${payload.id}`, { method: 'DELETE' })

    commit('DELETE', payload.id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
