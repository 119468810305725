import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { MotionPlugin } from '@vueuse/motion'
import { GesturePlugin } from '@vueuse/gesture'

import App from '@/App.vue'
import router from '@/router'
import { store, key } from '@/store'
import FontAwesome from '@/plugins/fontawesome'
import Rollbar from '@/plugins/rollbar'
// import { makeServer } from '@/mirage/server'
import { autofocus } from '@/directives/autofocus'

import '@/index.css'

// if (process.env.NODE_ENV === 'development') {
//   makeServer()
// }

const app = createApp(App)

app.use(router)
app.use(store, key)
app.use(Rollbar)
app.use(createHead())
app.use(MotionPlugin)
app.use(GesturePlugin)
app.use(FontAwesome)

app.directive('autofocus', autofocus)

app.mount('#app')
