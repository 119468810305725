import { App } from 'vue'
import { library, config, dom, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faStarfighter,
  faHomeAlt,
  faCalendarAlt,
  faFlask,
  faUser,
  faCog,
  faBars,
  faSearch,
  faEye,
  faEyeSlash,
  faSpinnerThird,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faMoneyCheckAlt,
  faSignOut,
  faUndoAlt,
  faSatelliteDish,
  faPencilAlt,
  faPlus,
  faTimes,
  faUserSlash,
  faStickyNote,
  faFilePlus,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faDoorOpen,
  faSort,
  faTrashAlt,
  faExternalLinkAlt,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faFilter,
  faClock,
  faInfoCircle,
  faExclamationTriangle,
  faBug,
  faBellOn,
  faCopy,
  faShieldCheck
} from '@rileytaylor/pro-regular-svg-icons'
import {
  faRedditAlien
} from '@rileytaylor/pro-brands-svg-icons'

const ICONS: IconDefinition[] = [
  faStarfighter,
  faHomeAlt,
  faCalendarAlt,
  faDoorOpen,
  faFlask,
  faUser,
  faCog,
  faBars,
  faSearch,
  faEye,
  faEyeSlash,
  faSpinnerThird,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faMoneyCheckAlt,
  faSignOut,
  faUndoAlt,
  faSatelliteDish,
  faPencilAlt,
  faPlus,
  faTimes,
  faUserSlash,
  faStickyNote,
  faFilePlus,
  faArrowLeft,
  faArrowRight,
  faPaperPlane,
  faSort,
  faTrashAlt,
  faExternalLinkAlt,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faFilter,
  faClock,
  faInfoCircle,
  faExclamationTriangle,
  faBellOn,
  faBug,
  faCopy,
  faShieldCheck,
  faRedditAlien,
]

export default {
  install(app: App) {
    // Load css ourselves
    config.autoAddCss = false

    // Load icons into the library
    library.add(...ICONS)

    // Watch for i tags using font awesome without the vue component
    // https://github.com/FortAwesome/vue-fontawesome#processing-i-tags-into-svg-using-font-awesome
    dom.watch()

    // Init font aweosome component
    app.component('FontAwesomeIcon', FontAwesomeIcon)
  },
}
