

import __layout_0 from '/src/layouts/default.vue'
export const layouts = {
'centered': () => import('/src/layouts/centered.vue'),
'default': __layout_0,
'terms': () => import('/src/layouts/terms.vue'),
}

export function setupLayouts(routes) {
  return routes.map((route) => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [route],
    }
  })
}
