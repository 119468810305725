<template>
  <div
    v-if="isMobile"
    class="navMobile"
  >
    <button
      type="button"
      class="navMobile-toggle"
      @click="onToggleMobileMenu"
    >
      <FontAwesomeIcon
        :icon="['far', 'bars']"
        fixed-width
      />
    </button>
    <img
      class="h-8 w-8"
      src="/icon.svg"
      alt="SubArrow"
    >
    <span class="nav-header-name">SubArrow</span>
  </div>

  <!-- TODO: why no work -->
  <!-- TODO: a11y - double check the motion reduce works  -->
  <Transition
    enter-active-class="transition duration-300 ease-in motion-reduce:transition-none"
    leave-active-class="transition duration-300 ease-in motion-reduce:transition-none"
    enter-class="opacity-0"
    leave-to-class="opacity-0"
  >
    <div
      v-if="showBackdrop"
      class="navMobileBackdrop"
    />
  </Transition>

  <Transition
    enter-active-class="animate__animated animate__fadeInLeft animate__faster"
    leave-active-class="animate__animated animate__fadeOutLeft animate__faster"
  >
    <div
      v-if="show"
      class="nav"
      :class="{ 'is-mobile': isMobile }"
    >
      <header
        v-if="!isMobile"
        class="nav-header"
      >
        <img
          class="nav-header-logo"
          src="/icon.svg"
          alt="SubArrow"
        >
        <span class="nav-header-name">SubArrow</span>
      </header>
      <div
        v-if="!isMobile"
        class="nav-divider"
      />
      <nav aria-label="main navigation">
        <ul class="nav-links">
          <TheNavigationLink
            name="Home"
            to="/"
            :icon="['far', 'home-alt']"
          />
          <TheNavigationLink
            name="Posts"
            to="/posts"
            :icon="['far', 'sticky-note']"
          />
          <TheNavigationLink
            name="Schedules"
            to="/schedules"
            :icon="['far', 'calendar-alt']"
          />
          <TheNavigationLink
            name="Research"
            to="/research"
            :icon="['far', 'flask']"
          />
          <li class="navigationLink navigationLink--account">
            <!-- TODO: not a11y friendly! -->
            <a
              class="navMenu-toggle"
              :class="{ 'is-active': expanded }"
              aria-haspopup="true"
              :aria-expanded="expanded"
              tabindex="0"
              @click="onAccountClick"
            >
              <FontAwesomeIcon
                :icon="['far', 'user']"
                fixed-width
              />
              <span class="ml-2">{{ name }}</span>
              <FontAwesomeIcon
                class="ml-2"
                :icon="accountMenuArrowIcon"
                fixed-width
              />
            </a>
            <ul
              class="navMenu-content"
              :class="{ 'navMenu--expanded': expanded }"
            >
              <TheNavigationLink
                name="Account"
                to="/account"
                :icon="['far', 'user']"
                @click="onAccountClick"
              />
              <TheNavigationLink
                name="Security"
                to="/account/security"
                :icon="['far', 'shield-check']"
                @click="onAccountClick"
              />
              <TheNavigationLink
                name="Billing"
                :to="{ path: '/account/billing' }"
                :icon="['far', 'money-check-alt']"
                @click="onAccountClick"
              />
              <TheNavigationLink
                name="Settings"
                :to="{ path: '/account/settings' }"
                :icon="['far', 'cog']"
                @click="onAccountClick"
              />
              <TheNavigationLink
                name="Log Out"
                :icon="['far', 'sign-out']"
                @click="onLogout"
              />
            </ul>
          </li>
        </ul>
      </nav>
      <footer class="nav-footer">
        <nav
          aria-label="helpful links"
          class="pt-2"
        >
          <ul class="nav-footer-links">
            <li>
              <a
                href=""
                target="_blank"
                rel="external noreferrer noopener nofollow"
              >About</a>
            </li>
            <li>
              <a
                href="mailto:support@subarrow.app"
                target="_blank"
                rel="external noreferrer noopener nofollow"
              >Support</a>
            </li>
            <li>
              <a
                href="/privacy"
                target="_blank"
              >Privacy</a>
            </li>
            <!-- <li>
              <a
                href="https://reddit.com"
                target="_blank"
                rel="external noreferrer noopener nofollow"
              >
                r/subarrow
              </a>
            </li> -->
          </ul>
        </nav>
        <hr class="nav-divider">
        <p class="pb-4">
          &copy; 2022 SubArrow
        </p>
      </footer>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef, watch, ref, Ref } from 'vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import TheNavigationLink from '@/components/TheNavigationLink.vue'

export default defineComponent({
  components: {
    TheNavigationLink,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const show: Ref<boolean> = ref(false)
    const name: ComputedRef<string> = computed(() => store.getters['account/username'])

    // Mobile menu management
    const breakpoints = useBreakpoints(breakpointsTailwind)
    const isMobile = breakpoints.smaller('md')
    const showBackdrop = computed(() => isMobile.value && show.value)
    watch(
      () => isMobile.value,
      (breakpoint) => (breakpoint ? (show.value = false) : (show.value = true))
    )
    const onToggleMobileMenu = () => (show.value = !show.value)

    // Ensure the menu if visible on render in non-mobile views on load
    if (!isMobile.value) {
      show.value = true
    }

    // Account Submenu management
    const expanded: Ref<boolean> = ref(false)
    const accountMenuArrowIcon: ComputedRef<string[]> = computed(() => expanded.value ? ['far', 'angle-up'] : ['far', 'angle-down'])

    const onAccountClick = () => expanded.value = !expanded.value

    const onLogout = async () => {
      await store.dispatch('auth/logout')

      router.push({ name: 'login' })
    }

    return {
      show,
      name,
      isMobile,
      showBackdrop,
      onToggleMobileMenu,
      expanded,
      accountMenuArrowIcon,
      onAccountClick,
      onLogout,
    }
  },
})
</script>
