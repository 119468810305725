<template>
  <li class="navigationLink">
    <Component
      :is="to ? 'router-link': 'a'"
      :to="to"
      :class="{ 'is-active': makeActive }"
    >
      <FontAwesomeIcon
        :icon="icon"
        fixed-width
      />
      <span class="ml-2">
        {{ name }}
      </span>
    </Component>
  </li>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    const route = useRoute()

    const makeActive: ComputedRef<boolean> = computed(() => {
      if (props.to === '/' && route.path.length > 1) {
        // Only highlight home if it's just /
        return false
      }

      return route.path.startsWith(props.to)
    })

    return {
      makeActive,
    }
  },
})
</script>
