<template>
  <div class="text-center mt-24">
    <h2>Welcome, <span class="text-brand-60">{{ username }}</span>!</h2>
    <p class="mt-8">
      Someday this page will be useful.
    </p>
    <p class="mt-8">
      Until then, then make use of the fancy sidebar to the left :)
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from 'vue'
import { useHead } from '@vueuse/head'
import { useStore } from '@/store'

const APP_TITLE = import.meta.env.VITE_APP_TITLE

useHead({
  title: APP_TITLE as string,
})

const store = useStore()

const username: ComputedRef<string> = computed(() => store.getters['account/username'])

</script>
