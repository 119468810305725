import { createFetch, MaybeRef, UseFetchOptions, until } from '@vueuse/core'
import { store } from '@/store'
import { Dict } from '@/types'

type CustomHeaders = HeadersInit & {
  Authorization: string
  'Content-Type': string
}

type ResponseErrorData = Dict<string | string[]> | null

export type RequestResult = {
  ok: boolean
  status: number
  data?: unknown
}

interface ResponseErrorInterface {
  status: number
  error: string
  data: ResponseErrorData
  message: string
}

export class ResponseError extends Error implements ResponseErrorInterface  {
  status: number
  error: string
  data: ResponseErrorData
  message: string

  constructor (status: number, error: string, data: ResponseErrorData) {
    const message = `ResponseError: ${status} - ${data}`
    super(message)
    this.status = status
    this.error = error
    this.data = data
    this.message = message
  }
}

export class AuthError extends Error {
  message: string

  constructor() {
    const message = `AuthError: Login Required`
    super(message)
    this.message = message
  }
}

export type Pagination = {
  next: string | null,
  previous: string | null,
  count: number | null,
  offset: number | null,
}

interface PaginationErrorInterface {
  pagination: Pagination
  directionError: string
  message: string
}

export class PaginationError extends Error implements PaginationErrorInterface {
  pagination: Pagination
  directionError: string
  message: string

  constructor(pagination: Pagination, directionError: string) {
    const message = `PaginationError: ${directionError} is not valid`
    super(message)
    this.pagination = pagination
    this.directionError = directionError
    this.message = message
  }
}

export const BASE_URL: string = import.meta.env.VITE_BASE_API_URL as string

export const isOk = (code: number): boolean => code >= 200 && code < 400

const useFetch = createFetch({
  baseUrl: BASE_URL,
  options: {
    async beforeFetch({ options }) {

      const headers = options.headers as CustomHeaders

      if (options.credentials !== 'omit') {
        const token = store.state.auth.accessToken

        if (!token) {
          throw new AuthError()
        }

        headers['Authorization'] = `Bearer ${token}`
      }
      headers['Content-Type'] = 'application/json'

      return { options }
    },
  },
  fetchOptions: {
    mode: 'cors',
  },
})

export const request = async (url: MaybeRef<string>, options: RequestInit, useFetchOptions?: UseFetchOptions): Promise<RequestResult> => {

  // Set some defaults
  const optionsConfig: RequestInit = Object.assign({
    credentials: 'same-origin',
  }, options)

  const { statusCode, data, error, isFinished } = useFetch(url, optionsConfig, useFetchOptions || {}).json()

  await until(isFinished).toBe(true)

  const ok: boolean = isOk(statusCode.value)

  if (!ok && statusCode.value === 401 && data.value.code === 'token_not_valid') {
    throw new AuthError()
  }

  if (!ok) {
    throw new ResponseError(statusCode.value, error.value, data.value)
  }

  return { ok, status: statusCode.value, data: data.value }
}
