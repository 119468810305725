import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore, Commit, Dispatch } from 'vuex'
import auth, { AuthState } from '@/store/modules/auth'
import account, { AccountState } from '@/store/modules/account'
import redditAccounts, { RedditAccountsState } from '@/store/modules/reddit-accounts'
import posts, { PostsState } from '@/store/modules/posts'
import subreddits, { SubredditsState } from '@/store/modules/subreddits'
import schedules, { SchedulesState } from '@/store/modules/schedules'

// For all the typing info, see https://next.vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function

export interface StateInterface {
  auth?: AuthState
  account?: AccountState
  redditAccounts?: RedditAccountsState
  posts?: PostsState
  subreddits?: SubredditsState,
  schedules?: SchedulesState,
}

export interface ActionInterface<State> {
  commit?: Commit
  dispatch?: Dispatch
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- getters types are too complicated to fret over
  getters?: any
  state?: State
  rootState?: StateInterface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- getters types are too complicated to fret over
  rootGetters?: any
}

export const key: InjectionKey<Store<StateInterface>> = Symbol()

export const store = createStore<StateInterface>({
  modules: {
    auth,
    account,
    redditAccounts,
    posts,
    subreddits,
    schedules,
  },
})

/**
 * Wraps useStore() with our StateInterface typings
 */
export function useStore() {
  return baseUseStore(key)
}
