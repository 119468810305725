import { createRouter, createWebHistory } from 'vue-router'
import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'
import { store, StateInterface } from '@/store'
import { Store } from 'vuex'

const NO_AUTH_ROUTES: Set<string> = new Set([
  'login',
  'login-register',
  'password-reset',
  'email-verification',
  'login-forgot-password',
  'login-forgot-username',
  'terms',
  'privacy',
])

const getAuthStatus = async (store: Store<StateInterface>): Promise<boolean> => store.dispatch('auth/checkAuth')

const routes = setupLayouts(generatedRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeResolve(async (to) => {
  const isAuthed = await getAuthStatus(store)

  if (!NO_AUTH_ROUTES.has(to.name as string) && !isAuthed) {
    return { name: 'login' }
  }

  if (isAuthed) {
    const userId: string = store.getters['account/userid']

    if (!userId) {
      await store.dispatch('account/getAccount')
    }
  }

  return true
})

export default router
